import { graphql } from "gatsby"
import React from "react"
import ImageCover from "../components/ImageCover"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function NotFoundPage({ data }) {
  const backdrop = data.backdrop
  return (
    <Layout relative={false}>
      <Seo title="404: Not found" />
      <div style={{ display: "flex", flexGrow: "1", flexDirection: "column" }}>
        <ImageCover
          image={backdrop.nodes[0].childImageSharp.gatsbyImageData}
          heading={
            "404: Page Not Found You just hit a page that no longer exist.."
          }
          third
          truedat={true}
          arrow={false}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    backdrop: allFile(filter: { relativePath: { regex: "/wine404/" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 1800, quality: 60)
        }
      }
    }
  }
`
